const decisionsModule = () => {

    //variables
    const viewContainer = document.querySelector('[data-view-container]');
    const decisionBtns = document.querySelectorAll('[data-buttons-decision]');
    const decisionViews = document.querySelectorAll('[data-view-decision]');

    for (let i = 0; i < decisionBtns.length; i++) {
        decisionBtns[i].addEventListener('click', () => {
            if (!decisionViews[i].classList.contains('opened')) {
                //if others buttons is active then close it all
                for (let b = 0; b < decisionBtns.length; b++) {
                    if (decisionBtns[b].classList.contains('active')) {
                        decisionBtns[b].classList.remove('active');
                    }
                }

                //change button to active
                decisionBtns[i].classList.add('active');

                //if others elements is opened then close it
                for (let d = 0; d < decisionBtns.length; d++) {
                    if (decisionViews[d].classList.contains('opened')) {
                        decisionViews[d].classList.remove('opened');
                        decisionViews[d].classList.add('dn');
                    }
                }

                //open the element
                decisionViews[i].classList.remove('dn');
                setTimeout(() => {
                    decisionViews[i].classList.add('opened');
                }, 1);
            }
        });
    }

    //slider bts 
    const sliderBtnsContainers = document.querySelectorAll('[data-v-slider-btns]');

    for (let sliderBtnsContainer of sliderBtnsContainers) {
        const sliderBtns = sliderBtnsContainer.querySelectorAll('.slides-btns__item');
        
        for (let i = 0; i < sliderBtns.length; i++) {
            sliderBtns[i].addEventListener('click', () => {
                if (!decisionViews[i].classList.contains('opened')) {
                    //if others buttons is active then close it all
                    for (let b = 0; b < decisionBtns.length; b++) {
                        if (decisionBtns[b].classList.contains('active')) {
                            decisionBtns[b].classList.remove('active');
                        }
                    }

                    //change button to active
                    decisionBtns[i].classList.add('active');

                    //if others elements is opened then close it
                    for (let d = 0; d < sliderBtns.length; d++) {
                        if (decisionViews[d].classList.contains('opened')) {
                            decisionViews[d].classList.remove('opened');
                            decisionViews[d].classList.add('dn');
                        }
                    }
    
                    //open the element
                    decisionViews[i].classList.remove('dn');
                    setTimeout(() => {
                        decisionViews[i].classList.add('opened');
                    }, 1);
                }
            });
        }
    }

}

export {decisionsModule};