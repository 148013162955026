const probileTrim = (inputElement) => {

   inputElement.addEventListener('input', () => {
      if(inputElement.value.charAt(0) === ' ') {
         inputElement.value = '';
      }
   });

}

export {probileTrim};