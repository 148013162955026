import {decisionsModule} from './modules/decisionsModule';
import {requestForm} from './mainPage/requestForm';
import {probileTrim} from './modules/probileTrim';

//input elements for probile trim
const nameInput = document.getElementById('name');
const familyInput = document.getElementById('family');
const emailInput = document.getElementById('email');
const companyInput = document.getElementById('company');
const nameTestInput = document.getElementById('test-name');
const familyTestInput = document.getElementById('test-family');
const postTestInput = document.getElementById('test-post');
const phoneTestInput = document.getElementById('test-phone');
const companyTestInput = document.getElementById('test-company');

//Async loading for not important JS files
document.addEventListener("DOMContentLoaded", () => {

    //UI modules
    decisionsModule();
    requestForm();
    probileTrim(nameInput);
    probileTrim(familyInput);
    probileTrim(emailInput);
    probileTrim(companyInput);
    probileTrim(nameTestInput);
    probileTrim(familyTestInput);
    probileTrim(postTestInput);
    probileTrim(phoneTestInput);
    probileTrim(companyTestInput);

});