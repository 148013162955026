const requestForm = () => {

    const requestModal = document.querySelector('[data-modal="request"]');
    const requestModalWrapper = requestModal.querySelector('[data-modal="text"]');
    const requestModalCloseBtn = requestModal.querySelector('[data-modal="close-btn"]');

    const sumbitBtn = document.querySelector('[data-request="submit-btn"]');
    const submitBtnText = sumbitBtn.querySelector('.text');
    const submitBtmSvg = sumbitBtn.querySelector('.svg');
    const form = document.getElementById('request-form');
    const fileInput = form.querySelector('.file-input');
    const fileBtn = form.querySelector('.section-request__brief');

    //modal close btn logic
    requestModalCloseBtn.addEventListener('click', () => {
        //close modal
        requestModal.classList.remove('opened');
        setTimeout(() => {
            requestModal.classList.add('dn');
        }, 401);
    });

    //file input switch to file name logic
    fileInput.addEventListener('input', () => {
        const fileName = fileInput.files[0].name;
        const fileBtnFileName = document.createElement('span');
        fileBtnFileName.classList.add('text-fzm__semibold');
        fileBtnFileName.innerHTML = fileName;
        fileBtn.innerHTML = '';
        fileBtn.appendChild(fileBtnFileName);
    });

    //form logic
    form.addEventListener('submit', async (e) => {
        e.preventDefault();
        e.stopPropagation();

        //change text to loading svg
        submitBtnText.classList.add('dn');
        submitBtmSvg.classList.remove('dn');
    
        //Creating FormData object
        const fData = new FormData(form);
        const fAction = form.getAttribute('action');
    
        //Sending form to server
        const fetchResponse = await fetch(fAction, {
            method: 'POST',
            body: fData
        });
    
        const result = await fetchResponse.json();

        //change loading svg to text
        submitBtnText.classList.remove('dn');
        submitBtmSvg.classList.add('dn');
            
        if (fetchResponse.ok) {

            if (!location.href.includes('ua')) {
                //validation translation (dont ask me why i do like this!)
                if (result.message.indexOf('Імя введено некорректно') > -1) {
                    result.message.splice(result.message.indexOf('Імя введено некорректно'), 1);
                    result.message.push('The name was entered incorrectly');
                }
                
                if (result.message.indexOf('Прізвище введено некорректно') > -1) {
                    result.message.splice(result.message.indexOf('Прізвище введено некорректно'), 1);
                    result.message.push('Last name entered incorrectly');
                }
                
                if (result.message.indexOf('Невалідна пошта')  > -1) {
                    result.message.splice(result.message.indexOf('Невалідна пошта'), 1);
                    result.message.push('Invalid mail');
                }
                
                if (result.message.indexOf('Назва компанії введена некорректно') > -1) {
                    result.message.splice(result.message.indexOf('Назва компанії введена некорректно'), 1);
                    result.message.push('Company name entered incorrectly');
                }

                if (result.message.indexOf('Дякуємо за вашу заявку! Найближчим часом з вами зв\'яжуться наші менеджери для подальшої співпраці з вами. Гарного вам дня!') > -1) {
                    result.message = 'Thank you for your application! Our managers will contact you in the near future for further cooperation with you. Have a good day!';
                    form.reset();
                }
                
                //activate modal
                requestModalWrapper.innerHTML = result.message;
                requestModal.classList.remove('dn');

                setTimeout(() => {
                    requestModal.classList.add('opened');
                }, 1);

                if (result.message.indexOf('Дякуємо за вашу заявку! Найближчим часом з вами зв\'яжуться наші менеджери для подальшої співпраці з вами. Гарного вам дня!') > -1) {
                    form.reset();
                }
            } else {
                //activate modal
                requestModalWrapper.innerHTML = result.message;
                requestModal.classList.remove('dn');

                setTimeout(() => {
                    requestModal.classList.add('opened');
                }, 1);
            }
        
        } else {
    
            console.log(result);
    
        }
    
    });

}

export {requestForm};